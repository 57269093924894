import { useUserStore } from '~/stores/user'

/**
 * The currently logged in user is of user.kind 'influencer'
 */
export default defineNuxtRouteMiddleware(() => {
  const { $localePath, $pinia } = useNuxtApp()
  const { IS_INF } = useUserStore($pinia)

  if (!IS_INF) return navigateTo($localePath('/'))
})
